<template>
  <v-container>
    <v-list>
      <v-list-item>
        <span>Dense Lists</span>
        <v-switch class="ml-auto" v-model="userSettings.denseLists"></v-switch>
      </v-list-item>
    </v-list>
  </v-container>
</template>

<script>
export default {
  name: 'Settings',
  data () {
    return {
      userSettings: this.$store.getters.userSettings
    }
  }
}
</script>

<style scoped>

</style>
